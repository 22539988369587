import { useEffect, useState } from "react";
import axios from 'axios';
import MoneyList from "components/MoneyList/MoneyList";

export default function Admin() {
  const [list, setList] = useState([]);

  const sentMoney = async () => {
    const qq = await axios.get('withdrawn/');
    setList(qq.data)
  }

  useEffect(()=>{
    sentMoney()
  },[])
  
  return (
    <MoneyList list={list}/>
  )
}