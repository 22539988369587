import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemButton,
  Checkbox,
  Link,
  Divider
} from '@mui/material/';
import { useState } from 'react';

export default function MoneyItem({ contact:{userId, sum, withdrawn}, divider}) {
  const [checked, setChecked] = useState(Boolean(withdrawn));

  return(
    <>
    {divider&&<Divider/>}

     <ListItem
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={()=>{setChecked(!checked)}}
                checked={checked}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemText primary={`${userId} поки id користувача`} />
              <ListItemText primary={`сума зняття ${sum}`} />
            </ListItemButton>
          </ListItem>
  </>
  )
}