import * as React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch } from 'react-redux';
import { login } from 'redux/auth/operations';

const validationSchema = yup.object({
  email: yup
    .string('Введіть email')
    .email('Не валідний email')
    .required("Email обов'язковий"),
  password: yup
    .string('Введіть пароль')
    .min(8, 'Пароль повинен бути мін 8 символів')
    .required("Пароль обов'язковий"),
});

export default function Login() {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      dispatch(login(data));
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Увійти
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }} autoComplete="off">
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            name="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Увійти
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item >
              <Link component={RouterLink} to="/register" variant="body2">
                {"Немає акаунту? Зареєструйтесь"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}