import { Box, TextField, Button } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { selectUser } from 'redux/auth/selectors';
import axios from 'axios';

export default function User() {
  const [sum, setName] = useState('');
  const { sale, cashBack, id } = useSelector(selectUser);


  const giveMoney = async (data) => {
    const qq = await axios.post('withdrawn/create', data);
    if (qq.data.id){
      toast.success('Заявку подано');
    }
  }


  const handleSubmit = e => {
    e.preventDefault();
    if (sum > cashBack && sum < 0) {
      toast.error('Не можливо зняти таку суму');
      return;
    }

    giveMoney({id:id, sum:sum})
    /* if (isIncludeContact(name, contacts)) {
      toast.error(`${name} is already in contacts`, {
        theme: 'colored',
      });
      return 
    }

    dispatch(addContact({name, number}));

    setName('');
    setNumber('');
    onClose();*/
  }
  
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      autoComplete="off"
      sx={{ p: "15px" }}
    >
      <p>Знижка {sale || '0'}%, сума бонусів {cashBack || '0'} грн</p>
      <TextField
        autoFocus
        margin="normal"
        label="Сума"
        name="sum"
        type="number"
        min
        inputProps={{
          pattern: "^[0-9]*$",
          min: 1
        }}
        fullWidth
        required
        variant="standard"
        value={sum}
        onChange={(e)=> {setName(e.target.value)}}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
      >Зняти кошти</Button>
    </Box>
  )
}