import { Box, TextField, Button } from "@mui/material";
import { useState } from "react";
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from "react-redux";
import { selectUser } from 'redux/auth/selectors';

export default function Rest() {
  const [sum, setSum] = useState('');
  const [uId, setUId] = useState('');
  const { name } = useSelector(selectUser);

  const sentMoney = async (data) => {
    const qq = await axios.post('spent/add', data);
    if (qq.data.result){
      toast.success('Відправлено');
    }
  }


  const handleSubmit = e => {
    e.preventDefault();
    if ( sum < 0) {
      toast.error('Не існуюча сума');
      return;
    }

    sentMoney({userId:uId,price:sum, institution:name})
  }
  
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      autoComplete="off"
      sx={{ p: "15px" }}
    >
      <TextField
        autoFocus
        margin="normal"
        label="індивідуальний № клієнта"
        name="id"
        type="number"
        min
        inputProps={{
          pattern: "^[0-9]*$",
          min: 1
        }}
        fullWidth
        required
        variant="standard"
        value={uId}
        onChange={(e)=> {setUId(e.target.value)}}
      />
      <TextField
        autoFocus
        margin="normal"
        label="Сума чеку"
        name="sum"
        type="number"
        min
        inputProps={{
          pattern: "^[0-9]*$",
          min: 1
        }}
        fullWidth
        required
        variant="standard"
        value={sum}
        onChange={(e)=> {setSum(e.target.value)}}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
      >Відправити</Button>
    </Box>
  )
}