
import {List, Paper} from '@mui/material/';
import ContactItem from 'components/MoneyItem';

export default function MoneyList({list}) {

  return (
    <Paper sx={{mt:"15px"}} square elevation={3}>
      <List disablePadding>
        {list.map((item, idx) => (
          <ContactItem key={item.id} contact={item} divider={idx!==0} />
        ))}
      </List>
    </Paper>
    
  )
}
