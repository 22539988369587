
import { Container } from "@mui/material";
import Admin from "components/Admin/Admin";
import Rest from "components/Rest/Rest";
import User from "components/User/User";
import { useSelector } from "react-redux";
import { selectUser } from 'redux/auth/selectors';
export default function Main() {
  const { role } = useSelector(selectUser);

  return (
    <Container maxWidth="sm" sx={{ mt: "15px" }}>
     {role === 'user'&&<User />}
     {role === 'rest'&&<Rest />}
     {role === 'admin'&&<Admin />}

    </Container>
  )

}