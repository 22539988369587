import { register, login, logout, refresh } from './operations';
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  user: {},
  jwt: null,
  isLoggedIn: false,
  isRefreshing: false,
};

const handleReject = (state, action) => {
  toast.error(action.payload);
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [register.fulfilled](state, { payload }) {
      state.user = {id:payload.id, name:payload.name, email:payload.email, role:payload.role, sale:payload.sale, cashBack:payload.cashBack};
      state.jwt = payload.jwt;
      state.isLoggedIn = true;
    },
    [register.rejected]: handleReject,

    [login.fulfilled](state, { payload }) {
      state.user = {id:payload.id, name:payload.name, email:payload.email, role:payload.role, sale:payload.sale, cashBack:payload.cashBack};
      state.jwt = payload.jwt;
      state.isLoggedIn = true;
    },
    [login.rejected]: handleReject,

    [logout.fulfilled](state) {
      state.isLoggedIn = false;
    },
    [logout.rejected]: handleReject,

    [refresh.pending](state) {
      state.isRefreshing = true;
    },
    [refresh.fulfilled](state, { payload }) {
      state.user = {id:payload.id, name:payload.name, email:payload.email, role:payload.role, sale:payload.sale, cashBack:payload.cashBack};
      state.isLoggedIn = true;
      state.isRefreshing = false;
    },
    [refresh.rejected](state) {
      state.isRefreshing = false;
    },
  },
});

export const authReducer = authSlice.reducer;
