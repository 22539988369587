import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = 'https://api.bardjur.com/api';

const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = '';
};

export const register = createAsyncThunk(
  'auth/register',
  async (userData, thunkAPI) => {
    try {
      console.log(userData);
      const response = await axios.post('/user/register', userData);
      setAuthHeader(response.data.jwt);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post('/user/login', userData);
      setAuthHeader(response.data.jwt);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    await axios.post('/user/logout');
    clearAuthHeader();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const refresh = createAsyncThunk('auth/refresh', async (_, thunkAPI) => {


  const { user } = thunkAPI.getState().auth;
   if (!user.id) {
    return thunkAPI.rejectWithValue('Unable to fetch user');
  }
  
  //setAuthHeader(jwt); 

 try {
    const response = await axios.get(`/user/${user.id}`);
    console.log(response.data);
    return response.data[0];
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});
